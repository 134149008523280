<div class="modal-header">
	<h4 class="modal-title pull-left">
		{{ "CONFIG_DASHBOARD.TITLE" | translate }}
	</h4>
	<button
		type="button"
		class="btn-close close pull-right"
		aria-label="Close"
		(click)="bsModalRef.hide()"
	>
		<span aria-hidden="true" class="visually-hidden">&times;</span>
	</button>
</div>
<div class="modal-body">
	<div class="container">
		<form [formGroup]="form">
			<app-checkbox
				[labelText]="
					'CONFIG_DASHBOARD.LABEL.SHOW_AGENDA_AND_FINANCES'
						| translate
				"
				formControlName="DASHBOARD_AGENDA"
				(changedValueFormControl)="changedValue($event)"
			></app-checkbox>
			<app-checkbox
				[labelText]="
					'CONFIG_DASHBOARD.LABEL.SHOW_BILLING_SERVICES' | translate
				"
				formControlName="DASHBOARD_FATSERVICOS"
				(changedValueFormControl)="changedValue($event)"
			></app-checkbox>
			<app-checkbox
				[labelText]="
					'CONFIG_DASHBOARD.LABEL.SHOW_BILLING_SALES_NFE' | translate
				"
				formControlName="DASHBOARD_FATVENDAS"
				(changedValueFormControl)="changedValue($event)"
			></app-checkbox
			><app-checkbox
				[labelText]="
					'CONFIG_DASHBOARD.LABEL.SHOW_BILLING_SALES_NFCE' | translate
				"
				formControlName="DASHBOARD_FATPDV"
				(changedValueFormControl)="changedValue($event)"
			></app-checkbox
			><app-checkbox
				[labelText]="
					'CONFIG_DASHBOARD.LABEL.SHOW_AVERAGE_TICKET_SALES'
						| translate
				"
				formControlName="DASHBOARD_FATTICKETNFCE"
				(changedValueFormControl)="changedValue($event)"
			></app-checkbox
			><app-checkbox
				[labelText]="
					'CONFIG_DASHBOARD.LABEL.SHOW_AVERAGE_TICKET_SERVICES'
						| translate
				"
				formControlName="DASHBOARD_FATTICKETPEDIDOS"
				(changedValueFormControl)="changedValue($event)"
			></app-checkbox
			><app-checkbox
				[labelText]="
					'CONFIG_DASHBOARD.LABEL.SHOW_INVENTORY_DATA' | translate
				"
				formControlName="DASHBOARD_ESTOQUE"
				(changedValueFormControl)="changedValue($event)"
			></app-checkbox
			><app-checkbox
				[labelText]="
					'CONFIG_DASHBOARD.LABEL.SHOW_SALES_BREAK_EVENT_CHARTS'
						| translate
				"
				formControlName="DASHBOARD_INDICESGRAFICOS"
				(changedValueFormControl)="changedValue($event)"
			></app-checkbox>
			<app-checkbox
				[labelText]="
					'CONFIG_DASHBOARD.LABEL.SHOW_SALES_TARGET' | translate
				"
				formControlName="DASHBOARD_META_VENDAS"
				(changedValueFormControl)="changedValue($event)"
			></app-checkbox>
			<app-checkbox
				[labelText]="
					'CONFIG_DASHBOARD.LABEL.SHOW_FINANCIAL_DATA' | translate
				"
				formControlName="DASHBOARD_DADOS_FINANCEIROS"
				(changedValueFormControl)="changedValue($event)"
			></app-checkbox>
			<app-checkbox
				[labelText]="
					'CONFIG_DASHBOARD.LABEL.SHOW_ANNIVERSARIES' | translate
				"
				formControlName="DASHBOARD_ANIVERSARIO"
				(changedValueFormControl)="changedValue($event)"
			></app-checkbox>
			<app-checkbox
				[labelText]="
					'CONFIG_DASHBOARD.LABEL.SHOW_PRODUCTS_EXPIRATION_DATE'
						| translate
				"
				formControlName="DASHBOARD_PROD_VALID"
				(changedValueFormControl)="changedValue($event)"
			></app-checkbox>
		</form>
	</div>
</div>
