import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import {
	ENV_CONFIG,
	EnvironmentConfig
} from "@core/environments/environment-config";
import { AuthService } from "@core/services/auth/auth.service";
import { MonthlyCustomer } from "@data/schemas/monthly-customer";
import { Observable } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class MonthlyCustomerService {
	API_BFF_URL = "";
	private readonly MONTHLY_CUSTOMER_ENDPOINT = "monthly-customer";

	constructor(
		private http: HttpClient,
		private authService: AuthService,
		@Inject(ENV_CONFIG) private config: EnvironmentConfig
	) {
		this.API_BFF_URL = `${config.environment.API_BFF}`;
	}

	createTransactionalCustomerAccountAsaas(): Observable<MonthlyCustomer> {
		return this.http.post<MonthlyCustomer>(
			`${this.API_BFF_URL}/${this.MONTHLY_CUSTOMER_ENDPOINT}/create-transactional-customer-account-asaas`,
			{ hashedFingerprint: this.authService.getHashedFingerprint() },
			{ withCredentials: true }
		);
	}
}
