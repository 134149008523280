import { Component } from "@angular/core";
import { LocalStorageService } from "@core/services/storage/local-storage.service";
import { MonthlyCustomer } from "@data/schemas/monthly-customer";
import { MonthlyCustomerService } from "@data/services/monthly-customer.service";
import { AlertsService } from "@shared/components/atlas/alerts/services";
import { LoadingOverlayService } from "@shared/components/atlas/loading-overlay/services/loading-overlay.service";
import { LoginResponse } from "@shared/schemas/complete-login";
import { UserStorage } from "@shared/schemas/enums/user-storage";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
	selector: "app-create-account-asaas-modal",
	templateUrl: "./create-account-asaas-modal.component.html",
	styleUrls: ["./create-account-asaas-modal.component.scss"]
})
export class CreateAccountAsaasModalComponent {
	isLoadingBtnCreateAccount = false;
	ASAAS_LOGIN_URL = "";
	ASAAS_FORGOT_PASSWORD_URL = "";

	constructor(
		private bsModalRef: BsModalRef,
		private localStorage: LocalStorageService,
		private monthlyCustomerService: MonthlyCustomerService,
		private alertsService: AlertsService,
		private loadingOverlayService: LoadingOverlayService
	) {}

	createTransactionalCustomerAccountAsaas(): void {
		this.isLoadingBtnCreateAccount = true;
		this.closeModal();
		this.loadingOverlayService.showLoadingOverlay({
			key: "global-loading-overlay"
		});
		this.monthlyCustomerService
			.createTransactionalCustomerAccountAsaas()
			.subscribe({
				next: (monthlyCustomer: MonthlyCustomer) => {
					if (monthlyCustomer) {
						this.setIsAccountPublicIdAsaasCreatedStorage(true);
						this.alertsService.success({
							key: "floating-alert-create-account-asaas",
							message: ""
						});
						this.isLoadingBtnCreateAccount = false;
						this.loadingOverlayService.closeLoadingOverlay(
							"global-loading-overlay"
						);
					}
				},
				error: () => {
					this.isLoadingBtnCreateAccount = false;
					this.loadingOverlayService.closeLoadingOverlay(
						"global-loading-overlay"
					);
				}
			});
	}

	private setIsAccountPublicIdAsaasCreatedStorage(value: boolean): void {
		const userLogin: LoginResponse | null =
			this.localStorage.get<LoginResponse>(UserStorage.UserLogin);
		if (userLogin) {
			userLogin.isAccountPublicIdAsaasCreated = value;
			this.localStorage.set<LoginResponse>(
				UserStorage.UserLogin,
				userLogin
			);
		}
	}

	closeModal(): void {
		this.bsModalRef.hide();
	}
}
