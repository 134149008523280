import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { PreferencesService } from "@data/services/preferences.service";
import { KeyDashbordPreferences } from "@shared/schemas/enums/preference";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
	selector: "app-config-dashboard",
	templateUrl: "./config-dashboard.component.html",
	styleUrls: ["./config-dashboard.component.scss"],
	providers: [UntypedFormBuilder]
})
export class ConfigDashboardComponent implements OnInit {
	form!: UntypedFormGroup;

	constructor(
		public bsModalRef: BsModalRef,
		private fb: UntypedFormBuilder,
		private preferencesService: PreferencesService
	) {}

	ngOnInit(): void {
		this.createForm();
		this.initializePreferences();
	}

	initializePreferences(): void {
		Object.keys(KeyDashbordPreferences).map((preference) => {
			this.preferencesService
				.getDashboardPreferences(preference)
				.subscribe(({ preference, value }) => {
					this.form.patchValue({
						[preference as keyof KeyDashbordPreferences]: value
					});
				});
		});
	}

	changedValue(event: { formControlname: string; value: boolean }) {
		this.preferencesService
			.newDashboardPreference(event.formControlname, event.value)
			.subscribe();
	}

	private createForm(): void {
		this.form = this.fb.group({
			DASHBOARD_AGENDA: [null],
			DASHBOARD_FATSERVICOS: [null],
			DASHBOARD_FATVENDAS: [null],
			DASHBOARD_FATPDV: [null],
			DASHBOARD_FATTICKETNFCE: [null],
			DASHBOARD_FATTICKETPEDIDOS: [null],
			DASHBOARD_ESTOQUE: [null],
			DASHBOARD_INDICESGRAFICOS: [null],
			DASHBOARD_PEDIDOS_NOTAS: [null],
			DASHBOARD_META_VENDAS: [null],
			DASHBOARD_DADOS_FINANCEIROS: [null],
			DASHBOARD_ANIVERSARIO: [null],
			DASHBOARD_PROD_VALID: [null]
		});
	}
}
