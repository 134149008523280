<div class="modal-header text-center">
	<h5 class="modal-title fw-bold w-100">
		{{ "MODAL_CREATE_ACCOUNT.TITLE" | translate }}
	</h5>
	<button
		type="button"
		class="btn-close close pull-right"
		aria-label="Close"
		(click)="closeModal()"
	>
		<span aria-hidden="true" class="visually-hidden">&times;</span>
	</button>
</div>

<div class="modal-body">
	<div class="table-panel">
		<div class="row">
			<div class="col text-center img-container">
				<img
					class="img-logo"
					src="/assets/images/asaas_logo.svg"
					alt=""
				/>
			</div>
		</div>
		<div class="row">
			<div class="col text-center">
				Crie sua conta Asaas até dia <b>30/09/2023</b> para continuar
				usando o Base normalmente. Não perca a chance de aproveitar os
				benefícios de ter uma Conta Digital integrada ao seu ERP.
			</div>
		</div>
	</div>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-secondary" (click)="closeModal()">
		{{ "MODAL_CREATE_ACCOUNT.BUTTON.CANCEL" | translate }}
	</button>

	<button
		(click)="createTransactionalCustomerAccountAsaas()"
		class="btn btn-primary"
	>
		{{ "MODAL_CREATE_ACCOUNT.BUTTON.CONFIRM" | translate }}
	</button>
</div>
